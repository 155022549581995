import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Utils
import { responsive } from "../../../utils/style";

// Components
import Container from "../../Container";
import Row from "../../Row";

// Styled Elements
const CertContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 80px;

  ${responsive.md`
    margin-bottom: 120px;
  `};

  ${responsive.lg`
    margin-top: 100px;
  `};
`;

const ImageWrapper = styled.div`
  height: 80px;
  flex: 1 0 50%;
  margin-bottom: 24px;
  align-items: center;
  justify-content: center;
  display: flex;

  ${responsive.sm`
    flex: 1 0 auto;
    margin-bottom: 0;
  `}

  ${responsive.md`
    height: 120px;
  `}

  &.b-corp {
    width: 73px;
  }

  &.usp {
    width: 134px;
  }

  &.informed-sport {
    width: 97px;
  }

  &.non-gmo {
    width: 164px;
  }
`;

const renderImage = ({ image, className }) => {
  return (
    <ImageWrapper className={className} key={`image-${className}`}>
      <GatsbyImage
        image={getImage(image)}
        alt={image?.description}
        style={{
          userSelect: "none",
          userDrag: "none",
          pointerEvents: "none",
          touchCallout: "none",
          height: "100%",
        }}
        imgStyle={{ objectFit: "contain" }}
      />
    </ImageWrapper>
  );
};

export const Certifications = ({ data }) => {
  const {
    bcorpLogo,
    uspCertificationLogo,
    informedSportImage,
    nonGMOCertificationLogo,
  } = data;

  const certificationImages = [
    { image: bcorpLogo, className: "b-corp" },
    { image: uspCertificationLogo, className: "usp" },
    { image: informedSportImage, className: "informed-sport" },
    { image: nonGMOCertificationLogo, className: "non-gmo" },
  ];

  return (
    <Container>
      <Row>
        <CertContainer className={"col-12 col-md-8 offset-md-2"}>
          {certificationImages.map((cert) => renderImage(cert))}
        </CertContainer>
      </Row>
    </Container>
  );
};

const CertificationsComponent = (props) => (
  <StaticQuery
    query={ComponentQuery}
    render={(data) => <Certifications {...props} data={data} />}
  />
);

export default CertificationsComponent;

// GraphQL
const ComponentQuery = graphql`
  query CertificationsQuery {
    bcorpLogo: contentfulAsset(contentful_id: { eq: "H7asu4LetbVFDrZk9cWwX" }) {
      node_locale
      description
      gatsbyImageData(
        layout: CONSTRAINED
        width: 73
        quality: 100
        placeholder: NONE
      )
    }
    uspCertificationLogo: contentfulAsset(
      contentful_id: { eq: "4oun3lVF7fcWAKQnS9ZC5d" }
    ) {
      node_locale
      description
      gatsbyImageData(
        layout: CONSTRAINED
        width: 300
        quality: 100
        placeholder: NONE
      )
    }
    informedSportCertificationImages: contentfulAsset(
      contentful_id: { eq: "1r4DLVBNrTKwDZsFliSP5a" }
    ) {
      node_locale
      description
      gatsbyImageData(
        layout: CONSTRAINED
        width: 300
        quality: 100
        placeholder: NONE
      )
    }
    nonGMOCertificationLogo: contentfulAsset(
      contentful_id: { eq: "22J2y1K1bdIvgNL3TYoVno" }
    ) {
      node_locale
      description
      gatsbyImageData(
        layout: CONSTRAINED
        width: 300
        quality: 100
        placeholder: NONE
      )
    }
  }
`;
