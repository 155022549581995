import { useEffect, useState } from "react";
import styled from "styled-components";
import { Color } from "../../utils/style";

const BackgroundElement = styled.div`
  position: fixed;
  top: -25%;
  right: 0;
  bottom: -25%;
  left: 0;
  z-index: -5;

  background: ${Color.ritualYellow};
  opacity: ${(p) => p.opacity};
  transition: opacity 0.5s ease;
  user-select: none;
`;

const Background = ({ refs, indexVisible, setIndexVisible }) => {
  useEffect(() => {
    const handleScroll = () => {
      if (!refs.current) return null;

      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const offset = window.innerHeight / 4;

      refs.current.forEach((section, index) => {
        const elementHeight = section.current.offsetHeight;
        const elementTop = section.current.offsetTop;
        const elementBottom = elementTop + elementHeight;

        if (scrollTop > elementTop - offset && scrollTop < elementBottom) {
          setIndexVisible(index);
        }
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [refs]);

  return <BackgroundElement opacity={0.06 * indexVisible} />;
};

export default Background;
