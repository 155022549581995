import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Components
import Container from "../../Container";
import Row from "../../Row";

// Utils
import classNames from "classnames/bind";
import {
  responsive,
  rem,
  yellowUnderline,
  rgba,
  Color,
} from "../../../utils/style";
import { stripWrappingParagraphTag } from "../../../utils/contentful";
import metrics from "../../../utils/metrics";

// Styled Elements
const JustifiedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;

  ${responsive.sm`
    flex-direction: row;
  `};
`;

const ContentText = styled.div`
  flex: 0 1 auto;
  padding-bottom: 56px;

  span.headline {
    font-size: ${rem(32)};
    padding-bottom: 24px;
    display: inline-block;
    line-height: 40px;
    font-weight: 500;

    ${responsive.md`
      font-size: ${rem(48)};
      line-height: 58px;
      padding-bottom: 32px;
      padding-top: 50px;
    `};
  }

  p.eyebrow {
    font-weight: 500;
    font-size: ${rem(12)};
    line-height: ${rem(18)};
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: ${rgba(Color.ritualBlue, 0.6)};
    margin-bottom: 16px;

    ${responsive.md`
      font-size: ${rem(16)};
      line-height: ${rem(24)};
    `}
  }

  ${yellowUnderline}
  &.text-offset {
    ${responsive.sm`
    margin-top: 40px !important;`}

    ${responsive.md`
    margin-top: 80px !important;
    `}
  }
`;

const Body = styled.div`
  margin-bottom: 0;
  font-size: ${rem(24)};
  line-height: ${rem(30)};
  font-weight: 300;
  letter-spacing: -0.01em;

  ${responsive.md`
      font-size: ${rem(32)};
      line-height: ${rem(40)};
      letter-spacing: -0.01em;
      font-weight: 300;
  `}
  a.click {
    font-weight: 300;
    font-size: ${rem(24)};
    line-height: ${rem(30)};
    letter-spacing: -0.01em;
    padding-top: 24px;
    display: inline-block;
    padding-bottom: 2px;
    border-bottom: 2px solid ${Color.ritualBlue};
    transition: opacity 0.2s ease-in-out !important;

    &:hover {
      opacity: 0.6;
    }

    ${responsive.md`
      font-size: ${rem(32)};
      line-height: ${rem(40)};

      padding-bottom: 4px;
    `}
  }
`;

const ContentImage = styled.div`
  flex: 0 1 auto;
  padding-bottom: 48px;
  order: -1;

  ${responsive.sm`
    padding-left: 0px;
    padding-right: 0px;
  
    &.left {
      order: -1;
    }

    &.right {
      order: 1;
    }
  `}
  &.image-offset {
    ${responsive.sm`
    margin-top: 40px;`}

    ${responsive.md`
    margin-top: 80px;`}
  }
`;

const Justified = ({ content, className }) => {
  const { text, image, imageAlignment, offset, eyebrow } = content;
  const parsedText = stripWrappingParagraphTag(text.childMarkdownRemark.html);

  function handleClick(e) {
    if (e.target.classList.contains("click")) {
      metrics.track("CTA Clicked", {
        title: e.target.innerText,
        location: "Made Traceable Page",
      });
    }
  }

  return (
    <Container className={className}>
      <Row>
        <JustifiedWrapper>
          <ContentText
            className={classNames("col-10 col-sm-5", {
              "offset-sm-1": imageAlignment === "left",
              "offset-sm-0": imageAlignment === "right",
              "offset-sm-1 offset-2": imageAlignment === "switch",
              "text-offset": offset === "text-offset",
            })}
          >
            {eyebrow && <p className={"eyebrow"}>{eyebrow}</p>}

            <Body
              onClick={handleClick}
              dangerouslySetInnerHTML={{
                __html: parsedText,
              }}
            />
          </ContentText>
          <ContentImage
            className={classNames("col-10 col-sm-5", {
              "offset-2 left offset-sm-0": imageAlignment === "left",
              "offset-2 right offset-sm-1": imageAlignment === "right",
              "left offset-sm-0": imageAlignment === "switch",
              "offset-2 image-offset": offset === "image-offset",
            })}
          >
            <GatsbyImage
              image={getImage(image)}
              fadeIn={true}
              alt={image.description}
              style={{
                width: "100%",
                height: "100%",
                userSelect: "none",
                userDrag: "none",
                pointerEvents: "none",
                touchCallout: "none",
              }}
            />
          </ContentImage>
        </JustifiedWrapper>
      </Row>
    </Container>
  );
};

export default Justified;
