import styled from "styled-components";

// Components
import Container from "../Container";
import Row from "../Row";

// Utils
import { Font, responsive, rem } from "../../utils/style";

const StyledSection = styled.section`
  position: relative;

  h2 {
    max-width: 208px;
    font-size: ${rem(48)};
    line-height: ${rem(54)};
    margin-left: 15px;
    margin-bottom: 56px;

    em {
      ${Font.dutch};
    }

    ${responsive.sm`
      margin-left: 0px;
    `}

    ${responsive.md`
      font-size: ${rem(100)};
      line-height: ${rem(100)};
      margin-bottom: 96px;
    `}
  }
`;

const SectionNumber = styled.span`
  height: 300px;
  width: max-content;
  position: relative;
  overflow: visible;
  float: right;
  right: -110px;
  top: 0;
  margin-bottom: -140px;
  font-size: ${rem(150)};
  line-height: ${rem(300)};
  opacity: 0.08;

  ${responsive.sm`
    right: -60px;
  `}

  ${responsive.md`
    font-size: ${rem(280)};
    right: -170px;
    top: 140px;
    margin-bottom: 0;
  `}

  ${responsive.lg`
    right: -130px;
  `}
`;

const TraceabilitySection = (props) => {
  const { section, index, children } = props;
  const { sectionTitle } = section;
  // Left pad section number
  const sectionNumber = (index + 1).toString().padStart(2, "0");
  const sectionNumberText = `${sectionNumber}&#8212;`;

  return (
    <StyledSection>
      <SectionNumber
        dangerouslySetInnerHTML={{
          __html: sectionNumberText,
        }}
      />
      <Container>
        <Row>
          <h2
            dangerouslySetInnerHTML={{
              __html: sectionTitle,
            }}
          />
        </Row>
      </Container>
      {children}
    </StyledSection>
  );
};

export default TraceabilitySection;
