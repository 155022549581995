import styled from "styled-components";
import { responsive } from "../../../utils/style";
import classNames from "classnames/bind";

const RadialGradient = styled.div`
  height: 400px;
  width: 400px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 214, 0, 0.8) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  position: absolute;
  z-index: -2;
  top: clac(50% - 200px);

  ${responsive.md`
    height: 800px;
    width: 800px;
    top: calc(50% - 400px);
  `}

  &.left {
    left: -200px;

    ${responsive.md`
      left: -400px;
    `}
  }

  &.middle {
    left: calc(50% - 200px);

    ${responsive.md`
      left: calc(50% - 400px);
    `}
  }

  &.right {
    right: -200px;

    ${responsive.md`
      right: -400px;
    `}
  }
`;
const sun = ({ content }) => {
  const { sunAlignment } = content;
  if (!sunAlignment) return null;

  return (
    <RadialGradient
      className={classNames({
        right: sunAlignment === "right",
        middle: sunAlignment === "middle",
        left: sunAlignment === "left",
      })}
    ></RadialGradient>
  );
};

export default sun;
