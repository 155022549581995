import styled from "styled-components";

// Components
import Container from "../../Container";

// Utils
import {
  responsive,
  rem,
  Color,
  rgba,
  yellowUnderline,
} from "../../../utils/style";

// Styled Elements
const TextOnlyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
`;

const ContentText = styled.div`
  padding-bottom: 56px;
  font-size: ${rem(24)};
  line-height: ${rem(30)};
  font-weight: 300;
  letter-spacing: -0.01em;
  padding-right: 0px !important;
  padding-left: 0px !important;

  ${yellowUnderline}

  ${responsive.md`
    font-size: ${rem(32)};
    line-height: ${rem(40)};
  `};
`;

const TextOnly = ({ content }) => {
  const { text } = content;
  if (!text) return null;
  return (
    <Container>
      <TextOnlyWrapper>
        <ContentText
          className={`col-12 col-sm-7 offset-sm-2`}
          dangerouslySetInnerHTML={{
            __html: text.childMarkdownRemark.html,
          }}
        />
      </TextOnlyWrapper>
    </Container>
  );
};

export default TextOnly;
