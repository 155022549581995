// Marquee is using ResizeObserver and does not provide a poly/ponyfill
import { ResizeObserver } from "../../../hooks/useResizeObserver";

import Marquee from "react-fast-marquee";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Components
import styled from "styled-components";
// Utils
import { Font, rem, responsive } from "../../../utils/style";

const MarqueeContainer = styled.div`
  overflow-x: hidden !important;
  display: flex !important;
  flex-direction: row !important;
  width: 100%;
`;

const IngCard = styled.div`
  max-width: 900px;
  margin-right: 64px;
  margin-bottom: 80px;

  ${responsive.md`
    margin-right: 96px;
    margin-bottom: 109px;
  `};
`;

const ContainingRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
  margin-left: 15px !important;
  margin-right: 15px !important;
`;

const StyledImageWrapper = styled.div`
  ${Font.circular};
  margin-right: 24px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  transform: translateZ(0);

  ${responsive.md`
    width: 120px;
    height: 120px;
  `};
`;

const LocationContainer = styled.div`
  margin-right: 0px;
  align-self: center;

  h4:first-of-type {
    ${Font.circular};
    font-style: normal;
    font-weight: 300;
    font-size: ${rem(40)};
    line-height: 50px;
    margin-bottom: 16px;

    ${responsive.md`
      font-size: ${rem(66)};
    `};
  }
  span {
    ${Font.circular};
    width: 100%;
    font-style: normal;
    font-size: ${rem(14)};
    font-weight: 300;
    color: #a1aac5;

    ${responsive.md`
      font-size: ${rem(24)};
    `};
  }
`;

const renderImage = (image) => {
  return (
    <GatsbyImage
      image={getImage(image)}
      fadeIn={true}
      alt={image.description}
      style={{
        width: "100%",
        height: "100%",
        userSelect: "none",
        userDrag: "none",
        pointerEvents: "none",
        touchCallout: "none",
      }}
    />
  );
};

const IngredientsMarquee = (props) => {
  const { ingredients } = props;
  return (
    <MarqueeContainer>
      <Marquee speed={60} gradient={false}>
        {ingredients.map((ingredient, index) => {
          const { image, manufacturingLocation, location } = ingredient;
          const { lat, lon } = location;
          const longitude = lon.toString().substring(0, 7);
          const latitude = lat.toString().substring(0, 7);
          return (
            <IngCard key={`ingredient-${index}`}>
              <ContainingRow>
                <StyledImageWrapper md="4">
                  {renderImage(image)}
                </StyledImageWrapper>
                <LocationContainer md="8">
                  <h4>{manufacturingLocation}</h4>
                  <span>
                    {latitude + `\u00B0 N,  `}
                    {longitude + `\u00B0  E`}
                  </span>
                </LocationContainer>
              </ContainingRow>
            </IngCard>
          );
        })}
      </Marquee>
    </MarqueeContainer>
  );
};

export default IngredientsMarquee;
