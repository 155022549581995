import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";

// Components
import Container from "../Container";
import Row from "../Row";
import Text from "../Text";

// Utils
import { stripWrappingParagraphTag } from "../../utils/contentful";
import { Icons } from "../../utils/react-svg";
import { rem, responsive } from "../../utils/style";

// Styled Elements
const Hero = styled.div`
  position: relative;
  margin-top: 120px;

  ${responsive.sm`
    margin-top: 160px;
  `}
`;

const StyledContainer = styled(Container).attrs({
  className: "container",
})`
  height: 100%;
  overflow: visible !important;
  margin-top: 52px;

  ${responsive.md`
    margin-top: 68px;
  `}
`;

const StyledRow = styled(Row).attrs({
  className: "row",
})`
  height: 100%;
`;

const LeftContainer = styled.div.attrs({
  className: "col-12 col-sm-7",
})`
  display: flex;
  flex-direction: column;
  overflow: visible;

  ${responsive.md`
    padding-left: 0px !important;
  `}

  h1 {
    font-size: ${rem(56)};
    line-height: ${rem(54)};
    letter-spacing: -2px;
    margin-bottom: 32px;

    .sup {
      position: relative;
      font-size: 40%;
      line-height: 0;
      vertical-align: baseline;
      top: -1.2em;
      left: -10px;

      ${responsive.md`
        left: -25px;
      `}
    }

    ${responsive.md`
      font-size: ${rem(100)};
      line-height: ${rem(100)};
      margin-bottom: 56px;
    `}
  }

  h2 {
    font-size: ${rem(32)};
    line-height: ${rem(40)};
    letter-spacing: -1px;
    margin-bottom: 24px;

    ${responsive.md`
      font-size: ${rem(40)};
      line-height: ${rem(50)};
      max-width: 599px;
    `}
  }

  p {
    font-size: ${rem(20)};
    line-height: ${rem(30)};
    max-width: 599px;
    font-weight: 300;
    padding-bottom: 64px;

    ${responsive.md`
      font-size: ${rem(24)};
      line-height: ${rem(36)};
    `}
  }
`;

const MobileHeroImageContainer = styled.div.attrs({
  className: "d-block d-sm-none offset-2",
})`
  margin: 16px -15px 48px 0;
  align-self: flex-end;
  height: 291px;
  width: 253px;
`;

const DesktopImageWrapper = styled.div.attrs({
  className: "d-none d-sm-block",
})`
  width: 33vw;
  height: 291px;
  overflow: hidden;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;

  ${responsive.md`
    width: 33vw;
    height: 405px;
  `}

  ${responsive.lg`
    width: 33vw;
    max-width: 600px;
    height: 558px;
  `}
`;

const BackgroundImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  opacity: 1;
  transition: opacity 0.5s ease;

  img {
    max-width: 100%;
  }

  &.fade {
    opacity: 0;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
`;

const Arrow = styled.span.attrs({
  className: "d-none d-sm-block",
})`
  position: absolute;
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  top: 400px;
  right: 24px;
  writing-mode: vertical-rl;
  text-orientation: mixed;

  ${responsive.md`
    top: 480px;
  `};

  ${responsive.lg`
    height: 650px;
    top: 650px;
    right: 40px;
  `};

  .arrow-icon {
    height: 90px;
    width: 30px;
    position: relative;
    right: 6px;
    padding-top: 32px;
  }
`;

const RadialGradient = styled.div`
  height: 400px;
  width: 400px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 214, 0, 0.8) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  position: absolute;
  top: -80px;
  left: -176px;
  z-index: -1;

  ${responsive.md`
    height: 800px;
    width: 800px;
    left: -272px;
  `}
`;

const renderImage = (image) => {
  return (
    <GatsbyImage
      image={getImage(image)}
      fadeIn={true}
      alt={image.description}
      style={{
        width: "100%",
        height: "100%",
        userSelect: "none",
        userDrag: "none",
        pointerEvents: "none",
        touchCallout: "none",
      }}
    />
  );
};

const TraceabilityHero = (props) => {
  const {
    heroHeadline,
    heroSubheadline,
    heroParagraph,
    heroImageMobile,
    heroImageDesktop,
    heroBackgroundImage,
    indexVisible,
  } = props;

  const headlineHtml = stripWrappingParagraphTag(
    heroHeadline.childMarkdownRemark.html,
  );

  return (
    <Hero>
      <StyledContainer>
        <DesktopImageWrapper>
          {renderImage(heroImageDesktop)}
        </DesktopImageWrapper>
        <Arrow>
          <Text id="traceability.hero.scroll" defaultMessage="Scroll" />
          <Icons.ArrowScroll className={"arrow-icon"} />
        </Arrow>
        <StyledRow>
          <LeftContainer>
            <h1
              dangerouslySetInnerHTML={{
                __html: headlineHtml,
              }}
            />
            <MobileHeroImageContainer>
              {renderImage(heroImageMobile)}
            </MobileHeroImageContainer>
            <div className="ml-sm-5">
              <h2>{heroSubheadline}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: heroParagraph.childMarkdownRemark.rawMarkdownBody,
                }}
              />
            </div>
          </LeftContainer>
        </StyledRow>
      </StyledContainer>
      <RadialGradient />
      <BackgroundImageContainer className={indexVisible > 0 ? "fade" : ""}>
        {renderImage(heroBackgroundImage)}
      </BackgroundImageContainer>
    </Hero>
  );
};

export default TraceabilityHero;
