import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Components
import Container from "../../Container";
import Row from "../../Row";

// Utils
import { responsive, rem, Color, yellowUnderline } from "../../../utils/style";
import classNames from "classnames/bind";

// Styled Elements
const BulletsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${responsive.sm`
    flex-direction: row;
  `}

  ${responsive.md`
    margin-bottom: 120px;
  `}
`;

const ImageWrapper = styled.div`
  flex: 1 1 auto;
  margin-bottom: 56px;

  &.image-offset {
    ${responsive.sm`
    margin-top: 40px;`}

    ${responsive.md`
    margin-top: 80px;`}
  }
`;

const BulletsWrapper = styled.div`
  &.text-offset {
    ${responsive.sm`
    margin-top: 40px;`}

    ${responsive.md`
    margin-top: 80px;
    `}
  }
  p {
    font-weight: 300;
    font-size: ${rem(24)};
    line-height: ${rem(30)};
    letter-spacing: -0.01em;
    border-top: 2px solid ${Color.ritualBlue};
    margin-bottom: 0;
    padding: 32px 0 48px;

    ${responsive.md`
      font-size: ${rem(32)};
      line-height: ${rem(40)};
      padding: 40px 0 58px;
    `}

    ${yellowUnderline}
  }
`;

const AdditionalLink = styled.a`
  font-weight: 300;
  font-size: ${rem(24)};
  line-height: ${rem(30)};
  letter-spacing: -0.01em;
  padding-bottom: 2px;
  border-bottom: 2px solid ${Color.ritualBlue};
  transition: all 0.2s ease-in-out !important;

  &:hover {
    opacity: 0.6;
  }

  ${responsive.md`
    font-size: ${rem(32)};
    line-height: ${rem(40)};

    padding-bottom: 4px;
  `}
`;

const Bullets = ({ content }) => {
  const { image, data, offset } = content;
  const bullets = data?.bullets;
  const additionalLink = data?.link;

  if (!bullets || !image) return null;

  return (
    <div>
      <Container>
        <Row>
          <BulletsContainer className={"col-12"}>
            <ImageWrapper
              className={classNames("col-8 col-sm-5", {
                "image-offset": offset === "image-offset",
              })}
            >
              <GatsbyImage
                image={getImage(image)}
                fadeIn={true}
                alt={image.description}
                style={{
                  userSelect: "none",
                  userDrag: "none",
                  pointerEvents: "none",
                  touchCallout: "none",
                }}
              />
            </ImageWrapper>
            <BulletsWrapper
              className={classNames("col-9 offset-2 col-sm-5 offset-sm-1", {
                "text-offset": offset === "text-offset",
              })}
            >
              {bullets.map((bullet, index) => {
                return (
                  <p
                    dangerouslySetInnerHTML={{ __html: bullet }}
                    key={`bullet-${index}`}
                  ></p>
                );
              })}
              {additionalLink && (
                <AdditionalLink href={additionalLink.url}>
                  {additionalLink.text}
                </AdditionalLink>
              )}
            </BulletsWrapper>
          </BulletsContainer>
        </Row>
      </Container>
    </div>
  );
};

export default Bullets;
