import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Components
import Container from "../../Container";
import Row from "../../Row";

// Utils
import {
  responsive,
  rem,
  rgba,
  Color,
  yellowUnderline,
} from "../../../utils/style";
import { stripWrappingParagraphTag } from "../../../utils/contentful";
import classNames from "classnames/bind";

const ContentText = styled.div`
  flex: 0 1 auto;
  padding-bottom: 56px;

  font-size: ${rem(24)};
  line-height: ${rem(30)};
  font-weight: 300;
  letter-spacing: -0.01em;
  margin-top: 256px;
  padding-bottom: 80px;

  ${responsive.sm`
    margin-top: 0px;
    padding-bottom: 80px;
  `}

  ${responsive.md`
    padding-bottom: 120px;
  `}
  
  &.text-offset {
    ${responsive.sm`
        margin-top: 40px !important;
      `}

    ${responsive.md`
        margin-top: 80px !important;
      `}
  }

  p {
    font-size: ${rem(24)};
    line-height: ${rem(30)};
    font-weight: 300;
    letter-spacing: -0.01em;

    ${yellowUnderline};

    ${responsive.md`
      font-size: ${rem(32)};
      line-height: ${rem(40)};
    `}

    ${responsive.lg`
      font-size: ${rem(32)};
      line-height: ${rem(40)};
    `}

    &.eyebrow {
      font-weight: 500;
      font-size: ${rem(12)};
      line-height: ${rem(18)};
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: ${rgba(Color.ritualBlue, 0.6)};
      margin-bottom: 16px;

      ${responsive.md`
        font-size: ${rem(16)};
        line-height: ${rem(24)};
      `}
    }
  }
`;

const ContentImageWrapper = styled.div`
  flex: 0 1 auto;
  padding-bottom: 56px;

  .image-offset {
    ${responsive.sm`
    margin-top: 40px;`}

    ${responsive.md`
    margin-top: 80px;`}
  }

  .img-style-left {
    user-select: none;
    user-drag: none;
    pointer-events: none;
    touch-callout: none;
    position: absolute;
    height: 200px; //delete with vqa
    width: 294px;
    left: 0;

    ${responsive.sm`
    height 187px; //delete in vqa
    width: 274px;
    `}

    ${responsive.md`
    height 250px; //delete in vqa
    width: 365px;
    `}

    ${responsive.lg`
    height: 340px; //delete in vqa
    width: 498px;
    `}
  }
  .img-style-right {
    user-select: none;
    user-drag: none;
    pointer-events: none;
    touch-callout: none;
    position: absolute;
    height: 181px; //delete with vqa
    width: 310px;
    right: 0;

    ${responsive.sm`
    height 187px; //delete in vqa
    width: 274px;
    `}

    ${responsive.md`
    height 249px; //delete in vqa
    width: 365px;
    `}

    ${responsive.lg`
    height: 340px;
    width: 498px;
    `}
  }
`;

const FullBleed = ({ content }) => {
  const { text, image, imageAlignment, offset, eyebrow } = content;

  const parsedText = stripWrappingParagraphTag(text.childMarkdownRemark.html);

  return (
    <Container>
      <Row>
        <ContentText
          className={classNames("col-10 col-sm-6", {
            "offset-sm-5 offset-1": imageAlignment === "left",
            "offset-sm-1": imageAlignment === "right",
            "text-offset": offset === "text-offset",
          })}
        >
          {eyebrow && <p className={"eyebrow"}>{eyebrow}</p>}
          <p
            dangerouslySetInnerHTML={{
              __html: parsedText,
            }}
          ></p>
        </ContentText>
        <ContentImageWrapper>
          <GatsbyImage
            className={classNames({
              "img-style-left": imageAlignment === "left",
              "img-style-right": imageAlignment === "right",
              "image-offset": offset === "image-offset",
            })}
            image={getImage(image)}
            fadeIn={true}
            alt={image.description}
          />
        </ContentImageWrapper>
      </Row>
    </Container>
  );
};

export default FullBleed;
