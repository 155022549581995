import { useState, useRef } from "react";
import styled from "styled-components";

// Components
import Container from "../Container";
import Row from "../Row";
import ScrollableOverflow from "../ScrollableOverflow";
import TraceabilityTab from "./TraceabilityTab";
import TraceabilityContent from "./TraceabilityContent";

// Utils
import { Color, rgba, responsive } from "../../utils/style";
import metrics from "../../utils/metrics";

// Styled Elements
const TabContainer = styled(Container)`
  overflow: visible !important;

  margin-bottom: 80px;

  ${responsive.md`
    margin-bottom: 120px;
  `}
`;

const StyledRow = styled(Row)`
  padding-left: 15px !important;
  padding-right: 15px !important;

  ${responsive.sm`
    padding-left: 0 !important;
    padding-right: 0 !important;
  `}
`;

const TraceabilityTabs = (props) => {
  const { tabs, ingredients, sectionTitle, sectionNumber } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);
  const [fadeTimeout, setFadeTimeout] = useState(null);

  const scrollableRef = useRef(null);

  const clickHandler = (index) => {
    scrollableRef.current.scrollToIndex(index, "center");

    setIsFading(true);

    metrics.track("CTA Clicked", {
      title: tabs[index].title,
      location: index + 1,
    });

    if (fadeTimeout) {
      clearTimeout(fadeTimeout);
    }

    setFadeTimeout(
      setTimeout(() => {
        setActiveIndex(index);
        setIsFading(false);
      }, 400),
    );
  };

  return (
    <>
      <TabContainer>
        <StyledRow>
          <ScrollableOverflow
            bleedLeft={true}
            bleedRight={true}
            centered={false}
            ref={scrollableRef}
          >
            {tabs.map((tab, index) => {
              return (
                <TraceabilityTab
                  clickHandler={clickHandler}
                  key={`tab-${index}`}
                  index={index}
                  active={activeIndex === index}
                  title={tab.title}
                />
              );
            })}
          </ScrollableOverflow>
        </StyledRow>
      </TabContainer>
      <TraceabilityContent
        sectionTitle={sectionTitle}
        sectionNumber={sectionNumber}
        content={tabs[activeIndex].content}
        ingredients={ingredients}
        isFading={isFading}
      />
    </>
  );
};

export default TraceabilityTabs;
