import { useRef } from "react";
import styled, { keyframes } from "styled-components";

import Justified from "./contentTypes/Justified";
import FullBleed from "./contentTypes/FullBleed";
import Bullets from "./contentTypes/Bullets";
import Stats from "./contentTypes/Stats";
import Certifications from "./contentTypes/Certifications";
import TextOnly from "./contentTypes/TextOnly";
import IngredientsMarquee from "./contentTypes/IngredientsMarquee";
import Sun from "./contentTypes/Sun";
import ScrollingTitle from "./ScrollingTitle";

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

// Styled Elements
const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1700px;
  margin: 0 auto;

  &.fade-in {
    opacity: 0;
    animation: ${fadeIn} 0.4s ease-in-out forwards;
  }

  &.fade-out {
    opacity: 1;
    animation: ${fadeOut} 0.4s ease-in-out forwards;
  }
`;

const renderContentType = (content, ingredients) => {
  switch (content.type) {
    case "full-bleed":
      return <FullBleed content={content} key={`content-${content.id}`} />;
    case "justified":
      return <Justified content={content} key={`content-${content.id}`} />;
    case "bullets":
      return <Bullets content={content} key={`content-${content.id}`} />;
    case "stats":
      return <Stats content={content} key={`content-${content.id}`} />;
    case "certifications":
      return <Certifications content={content} key={`content-${content.id}`} />;
    case "text-only":
      return <TextOnly content={content} key={`content-${content.id}`} />;
    case "marquee":
      return <IngredientsMarquee content={content} ingredients={ingredients} />;
    case "sun":
      return <Sun content={content} key={`content-${content.id}`} />;
    default:
      console.warn(`Cannot render component for type ${content.type}`);
      return null;
  }
};

const TraceabilityContent = ({
  content,
  ingredients,
  sectionTitle,
  sectionNumber,
  isFading,
}) => {
  const ref = useRef(null);
  return (
    <Content className={isFading ? "fade-out" : "fade-in"} ref={ref}>
      <ScrollingTitle
        sectionTitle={sectionTitle}
        sectionNumber={sectionNumber}
        elementRef={ref}
      />
      {content.map((contentElement) =>
        renderContentType(contentElement, ingredients),
      )}
    </Content>
  );
};

export default TraceabilityContent;
