import { forwardRef } from "react";
import styled from "styled-components";
import classNames from "classnames/bind";

// Utils
import { responsive, Color, rgba, rem } from "../../utils/style";

// Styled Elements
const Tab = styled.div`
  border-bottom: 2px solid ${rgba(Color.ritualBlue, 0.2)};
  padding-right: 48px;
  display: flex;
  box-sizing: content-box;

  ${responsive.md`
    border-bottom-width: 4px;
  `};

  button {
    position: relative;
    padding-bottom: 24px;
    background: none;
    border: none;
    padding-left: 0;
    padding-right: 0;
    transition: color 0.4s ease-in-out;

    &::after {
      content: " ";
      position: absolute;
      right: 0;
      bottom: -2px;
      left: 0;
      height: 2px;
      background-color: ${Color.ritualBlue};
      z-index: 2;
      opacity: 0;
      transition: opacity 0.4s ease-in-out;

      ${responsive.md`
        height: 4px;
        bottom: -4px;
      `};
    }

    h3 {
      width: 172px;
      color: ${rgba(Color.ritualBlue, 0.4)};
      font-size: ${rem(20)};
      line-height: ${rem(28)};
      white-space: pre-wrap;
      margin-bottom: 0;
      transition: color 0.4s ease-in-out;
    }
  }

  &:last-of-type {
    padding-right: 0;
  }

  &.active {
    button {
      color: ${Color.ritualBlue};

      &::after {
        opacity: 1;
      }

      h3 {
        color: ${Color.ritualBlue};
      }
    }
  }

  ${responsive.sm`
    button {
      h3 {
        width: 180px;
      }
    }
  `}

  ${responsive.md`
    padding-right: 72px;

    button {
      padding-bottom: 64px;

      h3 {
        width: 320px;
        font-size: ${rem(40)};
        line-height: ${rem(50)};
        letter-spacing: -0.02em;
      }
    }
  `}
`;

const TraceabilityTab = forwardRef((props, ref) => {
  const { title, active, clickHandler, index } = props;

  return (
    <Tab
      ref={ref}
      className={classNames({
        active,
      })}
    >
      <button onClick={() => clickHandler(index)}>
        <h3>{title}</h3>
      </button>
    </Tab>
  );
});

export default TraceabilityTab;
