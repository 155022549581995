import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import { rem, responsive } from "../../utils/style";

const VerticalTitle = styled.span.attrs({
  className: "d-none d-sm-block",
})`
  position: absolute;
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  font-weight: 300;
  top: 24px;
  right: 24px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  z-index: 10;
  white-space: nowrap;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;

  &.stick {
    top: unset;
    position: fixed;
    bottom: 24px;
    ${responsive.xxl`
     right: calc(((100vw - 1700px) / 2) + 40px);
    `}
  }

  &.end {
    top: unset;
    position: absolute;
    bottom: 24px;
  }

  em {
    font-style: normal;
  }

  ${responsive.lg`
    top: 40px;
    right: 40px;

    &.stick {
      top: unset;
      bottom: 40px;
    }

    &.end {
      top: unset;
      bottom: 40px;
      
    }
  `};
`;

const ScrollingTitle = ({ elementRef, sectionNumber, sectionTitle }) => {
  if (!sectionNumber) return null;
  // left pad section number
  const paddedSectionNumber = sectionNumber.toString().padStart(2, "0");
  const [scrollTitleClass, setScrollTitleClass] = useState("");
  const ref = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (!elementRef.current) return null;

      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const titleHeight = ref.current.getBoundingClientRect().height;
      const offset = window.innerHeight - titleHeight - 80;
      const { height, top } = elementRef.current.getBoundingClientRect();
      const elementHeight = height;
      const offsetTop = top + scrollTop;

      if (
        scrollTop > offsetTop - offset &&
        scrollTop < offsetTop - offset + elementHeight - (titleHeight + 80)
      ) {
        // Within the section
        setScrollTitleClass("stick");
      } else if (
        scrollTop >
        offsetTop - offset + elementHeight - (titleHeight + 80)
      ) {
        // After the section
        setScrollTitleClass("end");
      } else {
        // Before the section
        setScrollTitleClass("");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [elementRef]);

  return (
    <VerticalTitle ref={ref} className={scrollTitleClass}>
      {paddedSectionNumber}
      {" — "}
      <span
        dangerouslySetInnerHTML={{
          __html: sectionTitle,
        }}
      />
    </VerticalTitle>
  );
};

export default ScrollingTitle;
